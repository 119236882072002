import { render, staticRenderFns } from "./RecurrenceLeaveDialog.vue?vue&type=template&id=23f15819&scoped=true&"
import script from "./RecurrenceLeaveDialog.vue?vue&type=script&lang=js&"
export * from "./RecurrenceLeaveDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../Users/kcger/AppData/Roaming/npm/node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f15819",
  null
  
)

export default component.exports